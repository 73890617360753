import React from 'react';
import { connect } from 'react-redux'
import { BrowserView, MobileView, isMobile } from 'react-device-detect';
import { Row, Col, PageHeader, BackTop, Breadcrumb, List } from 'antd';
import { DoubleRightOutlined } from '@ant-design/icons';
import { isAuth, _setAxios, getParamTable } from '../../../../../lib/Helper';
import AuthRedirect from '../../../../../components/redirect/AuthRedirect'

import SearchTable from '../../../../../components/search-table/SearchTable';

import Desktop from './Desktop';
import Mobile from './Mobile';
import { Link } from 'react-router-dom/cjs/react-router-dom';

class PenilaianNilaiMapel extends React.Component {

  constructor(props) {
    const { tahun_ajaran_id, semester_id, rombel_id, mapel_id } = props.match.params
    super(props)
    this.state = {
      values: {},
      table: {
        loading_first: false,
        loading: false,
        data: [],
        search: "",
        pagination: {
          current: 1,
          pageSize: 10,
          total: null,
        },
        sorting: [],
        filtering: {
          tahun_ajaran_id: tahun_ajaran_id,
          semester_id: semester_id,
          rombel_id: rombel_id,
          mapel_id: mapel_id
        },
        // detail: {},
      },
    };
  }

  fetchTable = (isNew = false) => {
    const { table } = this.state
    const params = getParamTable("default", table)
    this.fetch(params, isNew);
  }

  fetch = async (params = {}, isNew = false) => {
    this.setState({
      table: {
        ...params,
        loading_first: isMobile ? (isNew ? true : false) : false,
        loading: true
      }
    });

    params = {
      ...params,
      pagination: {
        ...params.pagination,
        current: isMobile ? (isNew ? 1 : params.pagination.current) : params.pagination.current
      }
    }
    _setAxios("penilaian/siswa/table-sumatif", "POST", params).then(resp => {

      if (resp.status === true) {
        let data = isNew ? [] : this.state.table.data

        let current = params.pagination.current
        this.setState({
          table: {
            ...params,
            loading_first: false,
            loading: false,
            data: isMobile ? [...data, ...resp.data.list] : resp.data.list,
            pagination: {
              ...params.pagination,
              current: isMobile ? current + 1 : current,
              total: resp.data.info.total
            }
          }
        });
      } else {
        this.setState({
          table: {
            ...params,
            loading_first: false,
            loading: false,
          }
        })
      }
    })
  };

  // TODO
  // getHeader = () => {
  //   _setAxios("penilaian/siswa/rombel", "GET").then(resp => {
  //     if (resp.status === true) {
  //       this.setState({
  //         detail: resp?.data?.data || {},
  //       })
  //     }
  //   })
  // }

  // componentDidMount() {
  //   this.getHeader()
  // }

  render() {

    const access = this.props.privilege.access["/penilaian/nilai-mapel"]
    const action = access !== undefined ? access?.action : null

    if (!isAuth(this.props.privilege) || access === undefined || !action?.detail) {
      return <AuthRedirect />
    }

    const { table } = this.state;
    // const { detail } = this.state

    return (
      <>
        <PageHeader
          style={{
            padding: 0
          }}
          onBack={() => window.history.back()}
          subTitle={<Breadcrumb style={{ margin: '16px 0' }} separator={<DoubleRightOutlined />}>
            <Breadcrumb.Item>Penilaian</Breadcrumb.Item>
            <Breadcrumb.Item><Link to="/penilaian/nilai-mapel">Nilai Mata Pelajaran</Link></Breadcrumb.Item>
            <Breadcrumb.Item>Nilai Sumatif Lingkup Materi</Breadcrumb.Item>
          </Breadcrumb>}
        />

        {/* TODO <div className="site-layout-background" style={{ padding: 24 }}>
          <List
            itemLayout="horizontal"
            size="small"
            dataSource={[
              {
                title: "Rombongan Belajar",
                description: detail?.rombel_nama || "-",
              },
              {
                title: "Wali Kelas",
                description: detail?.wali_rombel || "-",
              },
              {
                title: "Kurikulum",
                description: detail?.kurikulum_nama || "-",
              },
            ]}
            renderItem={item => (
              <List.Item>
                <List.Item.Meta
                  title={item.title}
                  description={item.description}
                />
              </List.Item>
            )}
          />
        </div> */}
        <div className="site-layout-background" style={{ padding: 24, minHeight: 360 }}>
          <PageHeader
            className="site-page-header"
            title="Nilai Sumatif Lingkup Materi"
            subTitle={!isMobile ? `Data nilai sumatif` : ``}
          />

          <Row gutter={[16, 16]}>

            <Col xs={24} >
              <SearchTable table={table} fetch={this.fetch} />
            </Col>

            {/* LIST/TABLE DATA */}
            <Col xs={24}>
              <BrowserView>
                <Desktop
                  action={action}
                  state={this.state}
                  fetch={this.fetch}
                  fetchTable={this.fetchTable}
                />
              </BrowserView>
              <MobileView>
                <Mobile
                  action={action}
                  state={this.state}
                  fetch={this.fetch}
                  fetchTable={this.fetchTable}
                />
              </MobileView>
            </Col>

          </Row>
        </div>
        <BackTop />
      </>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    privilege: state.privilege,
  }
}

export default connect(mapStateToProps)(PenilaianNilaiMapel);

import React from 'react';
import { connect } from 'react-redux'

import { Table, Row, Col, Pagination, Tag, Dropdown, Menu, Button } from 'antd';
import { getParamTable } from '../../../../lib/Helper';
import { DownCircleOutlined, InfoCircleOutlined, SettingOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom/cjs/react-router-dom';

class Desktop extends React.Component {
  constructor(props) {
    super(props)
    this.state = {

    };
  }

  componentDidMount() {
    this.props.fetchTable()
  }

  render() {
    const { table } = this.props.state
    const { action } = this.props
    return (
      <>
        <Row gutter={[16, 16]}>
          <Col span={24}>
            <Table
              columns={[
                {
                  title: 'No',
                  dataIndex: 'rownum',
                  width: '2%',
                },
                {
                  title: 'Mata Pelajaran',
                  render: (a, row) => <><Tag style={{minWidth: "100px", textAlign: "center"}} color="blue">{row.mapel_singkatan || "-"}</Tag> {row.mapel_nama || "-"}</>
                },
                {
                  title: 'Guru Mapel',
                  render: (a, row) => row.mapel_gtk || "-"
                },
                {
                  title: 'Sumatif',
                  align: 'center',
                  render: (a, row) => parseFloat(row?.sumatif_nilai).toFixed(2) || "0"
                },
                {
                  title: 'Pas/Pat',
                  align: 'center',
                  render: (a, row) => parseFloat(row?.pas_nilai).toFixed(2) || "0"
                },
                {
                  title: 'Rapor',
                  align: 'center',
                  render: (a, row) => parseFloat(row?.rapor_nilai).toFixed(2) || 0
                },
                {
                  title: 'Capaian Kompetensi',
                  render: (a, row) => row.capaian_kompetensi || "-"
                }, {
                  title: <SettingOutlined />,
                  align: "center",
                  key: 'operation',
                  width: '2%',
                  render: (a, row) => (!action.detail ? "-" : <Dropdown trigger={['click']} overlay={<Menu>
                    {action.detail === true ? (<Menu.Item key={0}><Link to={"/penilaian/nilai-mapel/detail/" + row.tahun_ajaran_id + "/" + row.semester_id + "/" + row.rombel_id + "/" + row.mapel_id+"?mapel="+row.mapel_singkatan+"-"+row.mapel_nama}><InfoCircleOutlined /> Detail</Link></Menu.Item>) : ""}
                    </Menu>}>
                    <Button type="primary">Opsi <DownCircleOutlined style={{ fontSize: 11 }} /></Button>
                  </Dropdown>),
                },
              ]}
              rowKey={record => record.id}
              dataSource={table.data}
              pagination={false}
              loading={table.loading}
              size="small"
            />
          </Col>
          <Col xs={24} className="table-pagination">
            <Pagination
              size="small"
              current={table.pagination.current}
              pageSize={table.pagination.pageSize}
              total={table.pagination.total}
              showTotal={() => `Total ${table.pagination.total} items`}
              showSizeChanger
              onChange={(current, pageSize) => {
                const pagination = { current: current, pageSize: pageSize, total: table.pagination.total }
                const params = getParamTable("change", table, null, null, pagination)
                this.props.fetch(params)
              }}
            />
          </Col>
        </Row>
      </>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    privilege: state.privilege,
  }
}

export default connect(mapStateToProps)(Desktop);

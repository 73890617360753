import React from 'react';
import { connect } from 'react-redux'

import { Row, Col, Divider, List, Spin, Empty, Tag, Dropdown, Menu, Button } from 'antd';
import InfiniteScroll from 'react-infinite-scroll-component';
import { DownCircleOutlined, InfoCircleOutlined } from '@ant-design/icons';
import { Link } from '@mui/material';

class Mobile extends React.Component {
  componentDidMount() {
    this.props.fetchTable(true)
  }

  render() {
    const { table } = this.props.state
    const { action } = this.props
    return (
      <>
        <Row gutter={[16, 16]}>
          <Col xs={24}>
            <InfiniteScroll
              dataLength={table.data.length}
              next={this.props.fetchTable}
              hasMore={table.pagination.total != null && table.data.length < table.pagination.total}
              loader={<div style={{ textAlign: "center" }}><Spin size="small" /></div>}
              endMessage={
                table.loading_first ? "" : (table.data.length ? <Divider /> : <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description="Data tidak ditemukan" />)
              }
            >
              <List
                loading={table.loading_first}
                locale={{ emptyText: " " }}
                dataSource={table.data}
                renderItem={(row, i) => (
                  <List.Item
                    key={i}
                  >
                    <List.Item.Meta
                      title={<><Tag style={{ minWidth: "100px", textAlign: "center" }} color="blue">{row.mapel_singkatan || "-"}</Tag> {row.mapel_nama || "-"}</>}
                      description={<>
                        <Row gutter={[8, 8]}>
                          <Col xs={24}>
                            <small>
                              Guru Mapel: {row.mapel_gtk || "-"} <br />
                              Sumatif: {parseFloat(row?.sumatif_nilai).toFixed(2) || "0"} <br />
                              Pas/Pat: {parseFloat(row?.pas_nilai).toFixed(2) || "0"} <br />
                              Rapor: {parseFloat(row?.rapor_nilai).toFixed(2) || 0} <br />
                              Capaian Kompetensi: {row.capaian_kompetensi || "-"} <br />
                            </small>
                          </Col>
                        </Row>
                      </>}
                    />
                    <div>
                      <Dropdown trigger={['click']} overlay={<Menu>
                        {action.detail === true ? (<Menu.Item key={0}><Link to={"/penilaian/nilai-mapel/detail/" + row.tahun_ajaran_id + "/" + row.semester_id + "/" + row.rombel_id + "/" + row.mapel_id + "?mapel=" + row.mapel_singkatan + "-" + row.mapel_nama}><InfoCircleOutlined /> Detail</Link></Menu.Item>) : ""}
                      </Menu>}>
                        <div className="ant-dropdown-link tb-action">
                          <Button type="primary">Opsi <DownCircleOutlined style={{ fontSize: 11 }} /></Button>
                        </div>
                      </Dropdown>
                    </div>
                  </List.Item>
                )}
              />
            </InfiniteScroll>
          </Col>
        </Row>
      </>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    privilege: state.privilege,
  }
}

export default connect(mapStateToProps)(Mobile);

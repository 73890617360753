import React from 'react';
import { connect } from 'react-redux'
import { BrowserView, MobileView, isMobile } from 'react-device-detect';
import { Row, Col, PageHeader, BackTop, Breadcrumb, List, message } from 'antd';
import { DoubleRightOutlined } from '@ant-design/icons';
import { isAuth, _setAxios, getParamTable, getParamTempTableFiltering } from '../../../../lib/Helper';
import AuthRedirect from '../../../../components/redirect/AuthRedirect'

import SearchTable from '../../../../components/search-table/SearchTable';

import Desktop from './Desktop';
import Mobile from './Mobile';
import { NavFilter } from 'components/sorting-table';
import ModalFilter from '../modal/Filter';

const sortFields = [{
  value: "id",
  label: "Tanggal Pembuatan",
}]

class PenilaianNilaiMapel extends React.Component {

  constructor(props) {
    const { privilege } = props
    const { tahun_ajaran, semester } = privilege
    super(props)
    this.state = {
      values: {},
      table: {
        loading_first: false,
        loading: false,
        data: [],
        search: "",
        pagination: {
          current: 1,
          pageSize: 10,
          total: null,
        },
        sorting: [],
        filtering: {
          tahun_ajaran_id: tahun_ajaran?.id,
          semester_id: semester?.id
        },
        detail: {},
      },
      filter: {
        values: {
          tahun_ajaran_id: tahun_ajaran?.id,
          semester_id: semester?.id
        },
        visible: false,
      },
    };
  }

  fetchTable = (isNew = false) => {
    const { table } = this.state
    const params = getParamTable("default", table)
    this.fetch(params, isNew);
  }

  fetch = async (params = {}, isNew = false) => {
    this.setState({
      table: {
        ...params,
        loading_first: isMobile ? (isNew ? true : false) : false,
        loading: true
      }
    });

    params = {
      ...params,
      pagination: {
        ...params.pagination,
        current: isMobile ? (isNew ? 1 : params.pagination.current) : params.pagination.current
      }
    }
    _setAxios("penilaian/siswa/table", "POST", params).then(resp => {

      if (resp.status === true) {
        let data = isNew ? [] : this.state.table.data

        let current = params.pagination.current
        this.setState({
          table: {
            ...params,
            loading_first: false,
            loading: false,
            data: isMobile ? [...data, ...resp.data.list] : resp.data.list,
            pagination: {
              ...params.pagination,
              current: isMobile ? current + 1 : current,
              total: resp.data.info.total
            }
          }
        });
      } else {
        this.setState({
          table: {
            ...params,
            loading_first: false,
            loading: false,
          }
        })
      }
    })
  };

  getHeader = (tahunAjaranId, semesterId) => {
    _setAxios("penilaian/siswa/rombel/" + tahunAjaranId + "/" + semesterId, "GET").then(resp => {
      if (resp.status === true) {
        this.setState({
          detail: resp?.data?.data || {},
        })
      } else {
        this.setState({
          detail: {},
        })
      }
    })
  }


  // FILTER START ------------------------------------------
  setTempTableFilter = (name, value) => {

    let values = this.state.filter.values
    values[name] = value

    this.setState({
      filter: {
        ...this.state.filter,
        values: values
      }
    })
  };

  setGlobalFilter = (name, value) => {
    const { global_filter } = this.state

    let values = global_filter.values
    values[name] = value

    this.setState({
      global_filter: {
        ...global_filter,
        values: values
      }
    })
  };

  setTableFilterFromTemp = () => {
    const { table, filter } = this.state
    if (!filter.values?.tahun_ajaran_id || !filter.values?.semester_id) {
      message.error("Pilih tahun ajaran dan semester terlebih dahulu")
      return
    }

    this.getHeader(filter.values.tahun_ajaran_id, filter.values.semester_id)

    const params = getParamTempTableFiltering(table, filter.values)
    this.fetch(params, isMobile ? true : false)
    this.modalFilter(false)
  };

  modalFilter = (visible = false) => {
    const { filter } = this.state
    this.setState({
      filter: {
        ...filter,
        visible: visible,
      },
    })
  }
  // FILTER END ------------------------------------------


  componentDidMount() {
    const { privilege } = this.props
    const { tahun_ajaran, semester } = privilege
    const tahunAjaranId = tahun_ajaran?.id
    const semesterId = semester?.id

    this.getHeader(tahunAjaranId, semesterId)
  }

  render() {

    const access = this.props.privilege.access["/penilaian/nilai-mapel"]
    if (!isAuth(this.props.privilege) || access === undefined) {
      return <AuthRedirect />
    }
    const action = access !== undefined ? access.action : null

    const { table } = this.state;
    const { detail } = this.state

    return (
      <>
        <PageHeader
          style={{
            padding: 0
          }}
          onBack={() => window.history.back()}
          subTitle={<Breadcrumb style={{ margin: '16px 0' }} separator={<DoubleRightOutlined />}>
            <Breadcrumb.Item>Penilaian</Breadcrumb.Item>
            <Breadcrumb.Item>Nilai Mata Pelajaran</Breadcrumb.Item>
          </Breadcrumb>}
        />
        <div className="site-layout-background" style={{ padding: 24 }}>
          <List
            itemLayout="horizontal"
            size="small"
            dataSource={[
              {
                title: "Rombongan Belajar",
                description: detail?.rombel_nama || "-",
              },
              {
                title: "Wali Kelas",
                description: detail?.wali_rombel || "-",
              },
              {
                title: "Kurikulum",
                description: detail?.kurikulum_nama || "-",
              },
            ]}
            renderItem={item => (
              <List.Item>
                <List.Item.Meta
                  title={item.title}
                  description={item.description}
                />
              </List.Item>
            )}
          />
        </div>
        <div className="site-layout-background" style={{ padding: 24, minHeight: 360 }}>
          <PageHeader
            className="site-page-header"
            title="Nilai Mata Pelajaran"
            subTitle={!isMobile ? `Data nilai mata pelajaran` : ``}
          />

          <Row gutter={[16, 16]}>

            <Col xs={24} sm={18} md={19} >
              <SearchTable table={table} fetch={this.fetch} />
            </Col>

            {/* SORTING LIST/TABLE */}
            <Col xs={24} sm={6} md={5}>
              <NavFilter isFilter={true} table={table} fetch={this.fetch} modalFilter={this.modalFilter} sortFields={sortFields} />
            </Col>

            {/* LIST/TABLE DATA */}
            <Col xs={24}>
              <BrowserView>
                <Desktop
                  action={action}
                  state={this.state}
                  fetch={this.fetch}
                  fetchTable={this.fetchTable}
                />
              </BrowserView>
              <MobileView>
                <Mobile
                  action={action}
                  state={this.state}
                  fetch={this.fetch}
                  fetchTable={this.fetchTable}
                />
              </MobileView>
            </Col>

          </Row>
        </div>
        {
          this.state.filter.visible ?
            <ModalFilter
              values={this.state.filter.values}
              visible={this.state.filter.visible}
              hideModal={this.modalFilter.bind(this, false)}
              setTempTableFilter={this.setTempTableFilter}
              setTableFilterFromTemp={this.setTableFilterFromTemp}
            /> : ""
        }
        <BackTop />
      </>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    privilege: state.privilege,
  }
}

export default connect(mapStateToProps)(PenilaianNilaiMapel);
